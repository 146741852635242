import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Footer } from "./footer"
import { Menu } from "./menu"
import { Navbar } from "../navbar/navbar"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "./seo"

const LayoutPictures = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      strapi {
        picturesHomePage {
          DigicLogo {
            url
          }
        }
      }
    }
  `)

  const menuLinks = [
    { name: "Home", link: "/pictures" },
    { name: "Awards and screenings", link: "/pictures/awards-and-screenings" },
  ]

  return (
    <main>
      <CssBaseline />
      <SEO title="DIGIC Pictures" />
      <Navbar
        setMenuOpen={setMenuOpen}
        logo={data.strapi.picturesHomePage.DigicLogo.url}
        to="/pictures"
      />
      {children}
      <Menu
        isOpen={isMenuOpen}
        close={() => setMenuOpen(false)}
        menuLinks={menuLinks}
      />
      <Footer />
    </main>
  )
}

LayoutPictures.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPictures
