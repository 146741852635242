import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import LayoutPictures from "../../components/layout/layout-pictures"
import "pure-react-carousel/dist/react-carousel.es.css"
import WorksGrid from "../../components/works/works-grid"
import WorkHero from "../../components/works/work-hero"
import HeroSection from "../../components/hero-section"

const PicturesHomePage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        picturesHomePage {
          DigicLogo {
            url
          }
          PicturesHomeLead
          PicturesHomeButton
          SlideFirstImage {
            url
          }
          SlideSecondImage {
            url
          }
          SlideThirdImage {
            url
          }
        }
        works {
          id
          year_of_creation
          name
          type
          thumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const carosuelImages = [
    { image: data.strapi.picturesHomePage.SlideFirstImage.url },
    { image: data.strapi.picturesHomePage.SlideSecondImage.url },
    { image: data.strapi.picturesHomePage.SlideThirdImage.url },
  ]

  return (
    <LayoutPictures mainPage>
      <HeroSection
        logo={data.strapi.picturesHomePage.DigicLogo.url}
        description={data.strapi.picturesHomePage.PicturesHomeLead}
        buttonText={data.strapi.picturesHomePage.PicturesHomeButton}
        slideCount={carosuelImages.length}
        carosuelImages={carosuelImages}
      />
      <div id="section" style={{ padding: "0 24px" }}>
        <WorkHero />
        <WorksGrid
          data={data.strapi.works}
          slicedbyYear
          previewPath="pictures/"
        />
      </div>
    </LayoutPictures>
  )
}

export default PicturesHomePage
